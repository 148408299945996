<template>
  <div>
    <template v-if="!inEdit">
      <div class="field-content">
        <slot :value="value" :click="toggleEdit">
          <p v-if="Array.isArray(value) && value.length > 0" @click="toggleEdit">{{ value | displayArray }}</p>
          <p v-else-if="value && editComponent=='date'" @click="toggleEdit">{{ formatedDate}}</p>
          <p v-else-if="value && !Array.isArray(value)" @click="toggleEdit">{{ value }}</p>
          <p v-else @click="toggleEdit"><i>Enter {{label}}</i> </p>
        </slot>
      </div>
    </template>
    <template v-else>
      <ValidationProvider ref="provider" :rules="isRequired" v-slot="v">
        <div>
          <input
            v-if="editComponent === 'input'"
            ref="input"
            :id="name"
            class="form-control"
            :class="{ 'is-invalid': v.errors[0] }"
            v-bind="inputProps"
            v-model="selectedValue"
            @change="selectedValue = $event.target.value, toggleEdit()"
            @blur="toggleEdit(false)"
            
          />
          <textarea
            v-if="editComponent === 'textarea'"
            ref="input"
            :id="name"
            class="form-control"
            :class="{ 'is-invalid': v.errors[0] }"
            v-bind="inputProps"
            v-model="selectedValue"
            @change="selectedValue = $event.target.value, toggleEdit()"
            @blur="toggleEdit(false)"
          ></textarea>

          <div v-if="editComponent === 'checkbox'">
            <div class="check">
                <div class="custom-control" v-for="(value, index) in options" :key="index">
                    <input type="checkbox"
                      ref="input"
                      class="form-check-input" 
                      :class="{'is-invalid' : v.errors[0]}"
                      :name="name+'_'+index" 
                      v-bind="inputProps"
                      :value="getValue(value, index)" 
                      v-model="checkedValue" 
                      :id="name+'_'+index" 
                      @change="updateInput" 
                    >
                    <label class="" :for="name+'_'+index"> {{value}} </label>
              </div>
              <button type="button" classs="btn-outline-success btn-xs" @click="toggleEdit(false)">Save</button>
            </div>
          </div>
          
          <select
            v-if="editComponent === 'status'"
            ref="input"
            class="form-control"
            :class="{ 'is-invalid': v.errors[0] }"
            v-bind="inputProps"
            v-model="selectedValue"
            @change="selectedValue = $event.target.value, toggleEdit()"
            @blur="toggleEdit(false)"
          >
            <option v-for="option in selectOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>

          <div v-if="editComponent === 'date'">
            <i class="fas fa-trash fa-lg mr-2"></i>
            <date-picker
              v-model="date" 
              @dp-change="handleChange($event.target.value)" 
              :config="datePickerOptions" 

            />
          </div>
    
          <div v-if="(editComponent === 'radio' || editComponent === 'select') && options.length < 7">
            <div class="radio">
                <div class="custom-control" v-for="(value, index) in options" :key="index">
                    <input type="radio"
                      class="form-check-input" 
                      ref="input"
                      :class="{'is-invalid' : v.errors[0]}"
                      :name="name+'_'+index" 
                      v-bind="inputProps"
                      v-model="selectedValue" 
                      :value="getValue(value, name)" 
                      :id="name+'_'+index" 
                      @change="selectedValue = $event.target.value, toggleEdit(false)"
                      @blur="toggleEdit(false)"
                    >
                    <label v-if="name == 'age' || name == 'size' || 
                      name == 'primary_color_map' || name == 'secondary_color_map' || name == 'tertiary_color_map'  || name == 'coat_length'" class="" :for="name+'_'+index"> {{value.value}} </label>
                    <label v-else class="" :for="name+'_'+index"> {{value}} </label>
              </div>
            </div>
          </div>
          <select
            v-else-if="editComponent === 'select'"
            ref="input"
            class="form-control"
            :class="{ 'is-invalid': v.errors[0] }"
            v-bind="inputProps"
            v-model="selectedValue"
            @change="selectedValue = $event.target.value, toggleEdit()"
            @blur="toggleEdit(false)"
          >
            <option v-for="option in selectOptions" :key="option.value" :value="option.value">
              <span v-if="option && option.value">{{option.value}}</span> 
              <span v-else>{{option.label}}</span>
            </option>
          </select>
          <span v-if="v.errors[0]" class="text-danger font-size-1">{{ v.errors[0] }}</span>
        </div>
      </ValidationProvider>
    </template>
  </div>
</template>
<script>

//import datePicker from 'vue-bootstrap-datetimepicker';  
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import moment from 'moment-timezone'

export default {
  name: 'EditableValue',
  components : {},
  props: {
    editType: {
      type: String,
      default: 'text'
    },
    inputProps: {
      type: Object,
      default() { return {} },
    },
    value: {
      type: [String, Number, Array, Object],
      required: false,
    },
    valueType: {
      type: String,
      default: 'string',
    },
    options: {
      type: Array,
      default() { return [] },
    },
    asyncOptionsFn: {
      type: Function,
    },
    multiline: {
      type: Boolean,
    },
    label : {
      type : String,
    },
    name : {
      type : String,
    }
  },
  data() {
    return {
      inEdit: false,
      selectedValue: this.value,
      checkedValue : this.value || [],
      date: this.value || new Date(),
      asyncOptions : [],
      datePickerOptions: {
        format: 'YYYY-MM-DD',
        useCurrent: true,
        defaultDate : new Date(),
          icons:
                {
                    up: 'fa fa-angle-up',
                    down: 'fa fa-angle-down'
                },
          
      },   
    }
  },
  computed: {
    isRequired() {
      return this.inputProps.required ? 'required' : ''
    },
    editComponent() {
      switch (this.editType) {
        case 'text':
          return this.multiline ? 'textarea' : 'input'
          break;
        case 'option':
        case 'status':
          return 'status'
          break;
        case 'select':
        case 'radio':
          return 'select'
          break;
        case 'checkbox':
          return 'checkbox'
          break;
        case 'date':
          return 'date'
          break;
        default:
          return 'input'
      }
    },
    selectOptions() {
      return (this.asyncOptions && this.asyncOptions.length > 0 ? this.asyncOptions : this.options).map(o =>
        (typeof o === 'string' ? { value: o, label: o } : o));
    },
    formatedDate(value){
            return moment(this.value).format('YYYY-MM-DD')
        }
    
  },
  created() {},
  methods: {
    updateInput(event) {
      this.selectedValue = this.checkedValue
    },
    getValue(value, name){
      if (name == 'age' || name == 'size' || name == 'primary_color_map' || name == 'secondary_color_map' || name == 'tertiary_color_map'  || name == 'coat_length'){
        return value.value;
      }
      else{
        return value
      }
    },
    async toggleEdit(changed) {
      if (this.inEdit !== changed) {
        this.inEdit = !this.inEdit

        if (this.inEdit) {
          this.selectedValue = this.value
          this.loadAsyncOptions()
        } else{
          const convertedVal = this.valueType === 'numeric' ? parseFloat(this.selectedValue) : this.selectedValue
          if (this.value !== convertedVal) {
            const validationResult = await this.$refs.provider.validate(convertedVal)
            if (validationResult.valid) {
              this.$emit('change', convertedVal)
            }
          }
        }
      }
    },
    handleChange (value) {
      this.inEdit = !this.inEdit
      let date = moment(value).toDate()
      console.log('ui 1', value, date)
      this.$emit('change', date ) ;
    },
    loadAsyncOptions() {
      if ((!this.options || !this.options.length) && this.asyncOptionsFn) {
        this.asyncOptionsFn()
          .then(options => {
            this.asyncOptions = options
            this.$nextTick(() => {
              this.$refs.input.focus()
            });
          })
          .catch(err => console.log('failed to load async options: ', err))
      }
    },
    
    
  },
}
</script>

<style lang="scss" scoped>
.field-content::v-deep p {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3px;
}

.fa-chevron-left {
  color: black !important;
}

.fa-chevron-circle-up {
  background: yellow;
  border-radius: 50%;
  height: 1em;
  width: 1em;
}
</style>
