<template>
    
        <div v-if="field.visible_to_foster && field.visible_to_foster == true" class="col-lg-6">
            <BaseBirthdayField
                v-if="field.name == 'birthday'" class="mb-4"
                :field="field"
                :birthday="animal.birthday"
                :estimated="animal.est_birthday"
                :editable="field.edit_by_foster && user.foster_edit_animal_data"
            >
            </BaseBirthdayField>

            <BaseWeightField
                v-else-if="field.name == 'weight'" class="mb-4"
                :animal_id="animal._id"
                :field="field"
                :weights="animal.weights"
                :editable="field.edit_by_foster && user.foster_edit_animal_data"
            >
            </BaseWeightField>
            <BaseMicrochipField
                v-else-if="field.name == 'microchip'" class="mb-4"
                :animal_id="animal._id"
                :field="field"
                :microchip="animal.microchip"
                :microchipIssuer="animal.microchip_issuer"
                :editable="field.edit_by_foster && user.foster_edit_animal_data"
            >
            </BaseMicrochipField>
            
            <div v-else class="mb-4">
                <h5>{{field.label}}</h5>
                <div v-if="field.edit_by_foster && user.foster_edit_animal_data" >
                    <EditableValue
                        :label="field.label"
                        :name="field.name"
                        :editType="field.type"
                        :value="animal[field.name]"
                        :options="field.values"
                        :inputProps="{ required: field.required }"
                        @change="onFieldChange(field.name, $event, field.type )"
                    >
                    </EditableValue>
                </div>
                <div v-else>
                    <p v-if="animal[field.name]"> {{animal[field.name] | displayArray}} </p>
                    <p v-else>---</p>
                </div>
            </div>
        </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'AnimalFields',
    components: {  },
    directives: { },
    props : {
        animal: {
            type : Object,
            required :true,
        },
        field: {
            type : Object,
            required :true,
        },
    },
    data() {
        return {
            
        };
    },
    computed :{
        ...mapState(['user']),
        editable(){
            return this.animal.location_status && this.animal.location_status.toLowerCase() == 'foster' ? true : false
        }
    },
    mounted() {
        
    },
    methods: {
        onFieldChange(name, value, type) {
			console.log('field chagne: ', name, value, type);
			this.$store.dispatch('updateAnimal', { animal_id: this.animal._id, data: { [name]: value, old_data: this.animal[name], name :name, type : type} })
				.then((response) => {
					console.log('updated aniaml', response.data)
				})
				.catch((err) => console.log(err))
		}
    },
};
</script>

<style lang="scss" scoped>

</style>