<template>
<div class="container space-top-1">
	<div class="page-header">
		<div class="d-flex align-items-lg-center">
			<div class="mr-4">
				<img class="avatar avatar-xxl avatar-circle" :src="photoUrl" alt="Image Description" />
			</div>
			<div class="media-body">
				<div class="row">
					<div class="col-lg mb-3 mb-lg-0">
						<h1 class="h2 mb-1">{{ animal.id }} - <span v-if="animal.animal_name">{{animal.animal_name}}</span><span v-else>Unknown</span></h1>
						<div class="animal-status-wrapper">
							<EditableValue
								v-if="animal.animal_status != 'adopted' && animal.animal_status != 'Adopted' && animal.animal_status != 'pending_transport'"
								:value="animal.animal_status_id"
								editType="status"
								:asyncOptionsFn="loadStatuses"
								:inputProps="{ required: true }"
								@change="onFieldChange('animal_status_id', $event)"
								#default="{ click }"
							>
								<span class="badge badge-primary status-badge text-capitalize" @click="click"
									:style="{backgroundColor: animal.animal_status_color, color: animal.animal_status_color === '#1ab394' ? 'black' : 'white'}"
								>
									{{animal.animal_status}}
								</span>
							</EditableValue>
							<span v-else class="badge badge-primary status-badge text-capitalize"
								:style="{backgroundColor: animal.animal_status_color, color: animal.animal_status_color === '#1ab394' ? 'black' : 'white'}"
							>
								{{animal.animal_status}}
							</span>
						</div>
					</div>
					<div class="col-lg-auto align-self-lg-end text-lg-right">
						<!--<div class="mb-1 mr-1">LOF: 3.2 Days</div>-->
						<div class="mb-2 mr-1">
							<span @click="printAnimalProfile()"><font-awesome-icon class="list-group-icon" icon="print"/></span>
						</div>
						<div class="mb-2 mr-1">Adoption Fee : ${{animal.adoption_fee}}</div>
						<div v-if="animal.location_status && animal.location_status.toLowerCase() != 'foster'" class="mb-2 mr-1">Spay/Neuter Fee : ${{animal.altered_fee}}</div>
						<div v-if="animal.additionalFees && animal.additionalFees.length" class="mb-2 mr-1"> 
							<div class="mb-2" v-for="fee in animal.additionalFees" :key="fee._id">{{fee.description}} : ${{fee.amount}}</div>
						</div>
						<!--<div class="mr-1" v-else>
							<EditableValue
								:value="animal.altered_fee"
								valueType="numeric"
								editType="option"
								:asyncOptionsFn="loadAlteredFees"
								:inputProps="{ required: true }"
								@change="onFieldChange('altered_fee', $event)"
								#default="{ value, click }"
							>
								<p @click="click">Additional Fees: ${{ value }}</p>
							</EditableValue>
						</div>-->
						<div v-if="animal.animal_status != 'adopted'">
							<button class="btn btn-xs btn-primary mt-2" data-toggle="modal" :data-target="'#emailAdopter'" @click="emailAdopter()">Adoption Link</button>
						</div>
					</div>
				</div>
			</div>
			<AdoptionLinkModal
				v-if="showModal"
				:key="'modalId'+componentKey"
				:id="'emailAdopter'"
				title="Send Adoption Link"
				button="Send"
				:animal="animal"
				@action="sendEmai"
				:index="'action_'+componentKey"
			>
			</AdoptionLinkModal>

		</div>
	</div>
	
</div>
</template>

<script>
import axios from 'axios'
import config from '../../store/config'
import AdoptionLinkModal from './about/AdoptionLinkModal.vue'

export default {
	components: {
		AdoptionLinkModal,
	},
	props: {
		animal: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			animal_id: this.$route.params.animal_id,
			statuses: [],
			componentKey : 1,
			showModal : false,
		}
	},
	computed: {
		photoUrl() {
			return this.animal.photos && this.animal.photos.length > 0 ?
				`${this.animal.image_url}${this.animal.photos[0].filename}` : this.animal.photo
		}
	},
	mounted() {},

	methods: {
		editStatus(){
			if (this.animal.animal_status == 'adopted'){
				return false
			}
			else{
				return true
			}
		},
		loadAlteredFees() {
			return new Promise((resolve, reject) => axios
				.get(`${config.API_URL}/api/animal/${this.animal_id}/fees?type=additional_fees`)
				.then((resp) => resolve(resp.data.map(o => ({
					label: `${o.description} - ${o.amount}`,
					value: o.amount
				}))))
				.catch((err) => reject(err))
			)
		},
		loadStatuses() {
			return new Promise((resolve, reject) => axios
				.get(`${config.API_URL}/api/animal/${this.animal_id}/statuses`)
				.then((resp) => {
					this.statuses = resp.data;
					resolve(resp.data.map(o => ({
						label: o.label,
						value: o._id
					})))	
				})
				.catch((err) => reject(err))
			)
		},
		onFieldChange(type, value) {
			console.log('field chagne: ', type, value);
			// this.animal.description = value;
			let data = { [type]: value }

			if (type === 'animal_status_id') {
				const selectedOption = this.statuses.find(s => s._id === value)
				
				data = {
					...data,
					animal_status: selectedOption.label,
					animal_status_name: selectedOption.name,
					animal_status_color: selectedOption.color,
					old_data:this.animal.animal_status
				}
			}

			this.$store.dispatch('updateAnimal', { animal_id: this.animal._id, data })
				.then((response) => {
					console.log('updated aniaml', response.data)
				})
				.catch((err) => console.log(err))
		},
		emailAdopter(){
			console.log('email adopter')
			this.showModal = true;
			this.componentKey+= 1
			this.modalId = 'emailAdopter'
			this.title = "Email Adopter"
			this.action = "send"
			this.color = "btn-primary"
		},
		sendEmai(){

		},
		async printAnimalProfile(){
			console.log('click print')
			axios
			.get(`${config.API_URL}/api/animalPDF/generate/${this.$route.params.animal_id}`, { responseType: 'blob' } )
			.then((resp) => {
				console.log('printAnimalPDF', resp.data)
				const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }))
				window.open(url, '_blank')
			})
			.catch((err) => {
				console.log('pdf vue', err)
			})
		}
	},
}
</script>

<style scoped>
	.animal-status-wrapper {
		display: inline-block;
		width: auto;
	}
	.status-badge {
		cursor: pointer;
	}
</style>