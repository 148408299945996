<template>
<div>
  <div v-if="isLoading">
    <div class="container space-top-1">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="container space-top-1">
      <div class="row align-items-center">
          <div class="col-sm mb-3 mb-sm-0">
            <h1 class="h2 mb-0">My Foster Animals</h1>
          </div>
          <div class="col-sm-auto">
            <div class="btn-group">
              <button type="button" class="btn btn-outline-primary btn-xs dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="text-capitalize">{{status}}</span>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" @click="getSpinner('active')">Active</a>
                <a class="dropdown-item" @click="getSpinner('completed')">Completed</a>
              </div>
            </div>
          </div>
          <!-- End Select -->
      </div>

      <!-- Animal Item -->
      <div class="container space-1 space-bottom-2 space-bottom-lg-3">
        <div  v-if="animals && filteredAnimals.length > 0">
          <div v-for="(animal, index) in filteredAnimals" :key="index">
            <div class="card card-bordered card-hover-shadow mb-5">
              <div class="card-body">
                <div class="pb-1 mb-1">
                  <div class="row">
                    <div
                      class=" col-md-3 d-md-flex align-items-md-start flex-md-column mb-5 mb-md-0"
                    >
                      <a class="mb-3">
                        <img class="img-fluid w-100 rounded-lg" :src="animal.photo" alt="Animal Image" />
                      </a>
                    </div>

                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-sm-7">
                          <h2 class="mb-1">
                            <router-link tag="a"
                              :to="'animal-profile/'+animal.animal_id"
                            >{{ animal.id }} - 
                              <span v-if="animal.animal_name">{{animal.animal_name}}</span>
                              <span v-else>Unknown</span>
                            </router-link>
                          </h2>
                        </div>
                        <div class="col-sm-5 text-sm-right">
                          <h3 class="mb-1">
                            <a>${{animal.adoption_fee}}</a>
                          </h3>
                        </div>
                      </div>
                      <!-- End Row -->

                      <!-- Location -->
                      <div class="mb-3">
                        <h3 class="text-capitalize">{{animal.animal_status}}</h3>
                      </div>
                      <!-- End Location -->

                      <!-- Icon Blocks -->
                      <ul class="list-inline list-separator font-size-1 mb-3">
                        <li class="list-inline-item">
                          <i class="fas fa-bed text-muted mr-1"></i>
                          {{animal.breed}}
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-bath text-muted mr-1"></i>
                          {{animal.gender}}
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-couch text-muted mr-1"></i>
                          {{animal.age}}
                        </li>
                      </ul>
                      <!-- End Icon Blocks -->
                      <p class="font-size-1">{{animal.description}}</p>
                      <!-- End Row -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <ul class="list-inline list-separator small text-body">
                  <li class="list-inline-item">Start Date : {{animal.start_date | formatDate}}</li>
                  <li v-if="animal.end_date" class="list-inline-item">End Date : {{animal.start_date | formatDate}}</li>
                  <li class="list-inline-item">LOF : {{animal.lof}} day<span v-if="animal.lof > 1">s</span></li>
                </ul>
              </div>
            </div>
            <!-- End Card -->
          </div>
        </div>
        <div v-else> 
          <h3> You currently do not have any {{status}} fosters. </h3>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data() {
		return {
			animals: {},
      status : 'active',
			isLoading :true,
			filteredAnimals : {},
		}
	},

	mounted() {
		this.getAnimals()
	},
	computed: {
		...mapState(['user']),
	},
	methods: {
    getSpinner(status){
			this.isLoading = true
			setTimeout(() => {
				this.filterAnimals(status)
			}, 400)
		},
		filterAnimals(status='active') {
			if (this.animals){
				this.isLoading = false
				this.status = status
				switch(status){
          case 'all' :
						this.filteredAnimals = this.animals;
						break;
					case 'completed' :
						this.filteredAnimals = this.animals.filter(animal => animal.foster_status == 'ended');
						break;
					case 'active': 
						this.filteredAnimals = this.animals.filter(animal => animal.foster_status == 'active' || animal.foster_status == 'Active');
				}
			}else{
				return {}
			}
		},
		getAnimals() {
			let data = {
				shelter_id: this.user.shelter_id,
				people_id: this.user.people_id,
			}
			console.log('getAnimals components', data)
			this.$store
				.dispatch('getAnimals', data)
				.then((response) => {
					this.animals = response.data
          this.filterAnimals('active')
					
				})
				.catch((err) => console.log(err))
		},
	},
}
</script>

<style scoped></style>
